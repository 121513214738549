import axios, {AxiosError, AxiosResponse} from 'axios';
import {Address} from "../interfaces/Address";
import {Basket} from "../interfaces/Basket";
import {Member} from "../interfaces/Member";
import {Abo} from "../interfaces/Abo";
import {DeactivationReason} from "../interfaces/DeactivationReason";
import {toast} from "react-toastify";

const baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
//const baseURL = "https://api.fairstaerkt.at/api/v1/";
//const baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
const client = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

const authorizedClient = (key:any) => axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorize': key,
    },
    timeout: 25000
});

client.interceptors.request.use(req => {
    if (!req.params) {
        req.params = {};
    }
    req.params.m = Date.now();
    return req;
});

const BasketClient = {
    finalize: (basketId: number, memberId: number, shippingAddress: Address, billingAddress: undefined | Address, payment: string): Promise<AxiosResponse<ApiMessage>> => {
        return client.put('Basket/' + basketId + '/finalize', {
            BasketID: basketId,
            MemberID: memberId,
            Address: shippingAddress,
            BillingAddress: billingAddress,
            Payment: payment
        });
    },
    fetch: (id?: number): Promise<AxiosResponse<Basket>> => {
        return client.get('Basket/fetch' + (id !== undefined ? `/${id}` : ''));
    },
    fetchForGtag: (id?: number): Promise<AxiosResponse<Basket>> => {
        return client.get('Basket/fetch' + (id !== undefined ? `/${id}` : ''));
    },
    fetchAuthorized: (key: any, id?: number): Promise<AxiosResponse<Basket>> => {
        return authorizedClient(key).get('Basket/fetch' + (id !== undefined ? `/${id}` : ''));
    },
    fetchAuthorizedForGtag: (key: any, id?: number): Promise<AxiosResponse<Basket>> => {
        return authorizedClient(key).get('Basket/fetchForGtag' + (id !== undefined ? `/${id}` : ''));
    },
    deleteItem: (basketId: number, itemId: number): Promise<AxiosResponse<ApiMessage>> => {
        return client.post('Basket/modify', {
            Action: 'delete',
            BasketID: basketId,
            Payload: [{ProductID: itemId}]
        });
    },
    addItem: (basketId: number, productID: number, amount: number): Promise<AxiosResponse<ApiMessage>> => {
        return client.post('Basket/modify', {
            Action: 'add',
            BasketID: basketId,
            Payload: [
                {
                    ProductID: productID,
                    Quantity: amount
                }
            ]
        });
    },
    modifyItem: (basketId: number, itemId: number, productID: number, amount: number): Promise<AxiosResponse<ApiMessage>> => {
        return client.post('Basket/modify', {
            Action: 'modify',
            BasketID: basketId,
            Payload: [
                {
                    BasketItemID: itemId,
                    ProductID: productID,
                    Quantity: amount,
                }
            ]
        });
    },
    checkBasket: (basketId: number, memberId: number): Promise<AxiosResponse<{ error: boolean }>> => {
        return client.get('Basket/checkBasket', {
            params: {
                BasketID: basketId,
                MemberID: memberId,
            }
        });
    }
};

const MemberClient = {
    current: (key: any): Promise<AxiosResponse<null | Member>> => {
        return authorizedClient(key).get(`Member/current`);
    },
    addIdDocument: (key: any, ID: number, data: object): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).patch(`Member/${ID}`, data);
    },
    deleteIdDocument: (key: any, ID: number, data: object): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).patch(`Member/${ID}/deleteIdDocument`, data)
    },
    fetchMember: (id: number): Promise<AxiosResponse<Member>> => {
        return client.get(`Member/${id}`);
    },
    updateMember: (key: any, id: number, data: object): Promise<AxiosResponse<Member>> => {
        return authorizedClient(key).put(`Member/updateMemberData/${id}`, {id, data});
    },
    updatePassword: (key: any, id: number, data: object): Promise<AxiosResponse<Member>> => {
        return authorizedClient(key).put(`Member/updateMemberPassword/${id}`, data);
    },
    login: (email: string, password: string): Promise<AxiosResponse<Member>> => {
        return client.post('Member/doLogin', {email, password});
    },
    register: (type: string, firstname: string, surname: string, email: string, password: string, reason?: string, newsletter?: boolean): Promise<AxiosResponse<Member>> => {
        return client.post('Member/doRegistration', {
            Email: email,
            FirstName: firstname,
            Surname: surname,
            Password: password,
            Type: type,
            Reason: reason ?? '',
            NewsletterStatus: newsletter ?? false,
        });
    },
    registerVendor: (key: any, ID: number, Name: string, ShortDesc: string, LongDesc: string, Web: string, Email: string, Phone: string, AGB: string, ROW: string, UID: string, Country: string, ZIP: string, City: string, Street: string, StreetNr: string, headerImage: string, logo: string, PlanID: number) => {
        return authorizedClient(key).put(`Member/registerVendor/${ID}`, {
            ID,
            Name,
            ShortDesc,
            LongDesc,
            Web,
            Email,
            Phone,
            AGB,
            ROW,
            UID,
            Country,
            ZIP,
            City,
            Street,
            StreetNr,
            headerImage,
            logo,
            PlanID
        });
    },
    logout: (key: any): Promise<AxiosResponse> => {
        return authorizedClient(key).get('/Member/doLogout', {baseURL: `${process.env.REACT_APP_API_BASE_URL}`});
    },
    abos: (id: number): Promise<AxiosResponse<Abo[]>> => {
        return client.get(`Member/${id}/abos`);
    },
    shipments: (id: number): Promise<AxiosResponse<object[]>> => {
        return client.get(`Member/${id}/shipments`);
    },
    allShippingAddresses: (id: number): Promise<AxiosResponse<Address[]>> => {
        return client.get(`Member/${id}/allShippingAddresses`);
    },
    updateBillingAddress: (id: number, data: object): Promise<AxiosResponse<Member>> => {
        return client.put(`Address/${id}`, data);
    },
    giveFeedback: (data: object): Promise<AxiosResponse<Member>> => {
        return client.post(`FeedBack/`, data);
    },
}

const AboClient = {
    fetchAddress: (id: number): Promise<AxiosResponse<Abo>> => {
        return client.get(`Address/${id}`);
    },
    updateAddress: (id: number, data: object): Promise<AxiosResponse<any>> => {
        return client.put(`Abo/${id}/updateShippingAddress`, data);
    },
    updateAbo: (id: number, data: object): Promise<AxiosResponse<any>> => {
        return client.put(`Abo/${id}/updateAbo`, data);
    },
    togglePauseShipment: (id: number, data: object): Promise<AxiosResponse<any>> => {
        return client.put(`Abo/${id}/togglePause`, data);
    },
    deactivationReasons: (): Promise<AxiosResponse<DeactivationReason[]>> => {
        return client.get(`/AboDeactivationReason`);
    },
    disableAbo: (id: number, data: object): Promise<AxiosResponse<any>> => {
        return client.put(`Abo/${id}/disableAbo`, data);
    },
    enableAbo: (id: number, data: object): Promise<AxiosResponse<any>> => {
        return client.put(`Abo/${id}/enableAbo`, data);
    },
}

const CouponClient = {
    redeem: (code: string): Promise<AxiosResponse<ApiMessage>> => {
        return client.post(`Promotion/redeemCoupon`, {code});
    },
    inviteFriend: (data: object): Promise<AxiosResponse<Member>> => {
        return client.post(`CustomerInvite/sendInvite`, data);
    },
}

const VendorClient = {
    getVendorByURLSegment: (URLSegment: string): Promise<AxiosResponse<any>> => {
        return client.get('Vendor', {params: {'filter[URLSegment]': URLSegment}});
    },
    addImages: (key: any, ID: number, data: Array<object>): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).patch(`Vendor/${ID}/many/Images/sortOrder`, data);
    },
    removeImages: (key: any, ID: number): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).patch(`Vendor/${ID}/deleteImages`)
    },
    postData: (key: any, ID: number, data: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).put(`Vendor/${ID}`, data);
    },
    updateAddress: (key: any, ID: number, data: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).put(`Address/${ID}`, data);
    }
}

const ProdClient = {
    addImages: (key: any, ID: number, data: Array<object>): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).patch(`Product/${ID}/many/Images/sortOrder`, data);
    },
    removeImages: (key: any, ID: number): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).patch(`Product/${ID}/deleteImages`)
    }
}

const BookmarkClient = {
    add: (key: any, ID: number, prodID: number): Promise<AxiosResponse<ApiMessage>> => {
        return authorizedClient(key).post(`BookmarkCollection/toggle`, {params: {'0': 'add', '1': prodID, 'ID': ID}});
    },
    remove: (key: any, ID: number, prodID: number): Promise<AxiosResponse<ApiMessage>> => {
        return authorizedClient(key).post(`BookmarkCollection/toggle`, {
            params: {
                '0': 'remove',
                '1': prodID,
                'ID': ID
            }
        });
    },
}

const AuthVendorClient = {
    authPost: (key: any, URL: string, ID: number, data: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).put(`Vendor/${ID}/${URL}/`, data);
    },
    authPut: (key: any, URL: string, ID: number, data: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).put(`Vendor/${ID}/${URL}/`, data);
    },
    authDelete: (key: any, URL: string, ID: number, data?: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).delete(`Vendor/${ID}/${URL}/`, data);
    },
    authGet: (key: any, URL: string, ID: number, data?: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).get(`Vendor/${ID}/${URL}/`, data);
    }
}

const AuthClient = {
    authPost: (key: any, URL: string, ID: number, data: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).put(`${URL}/${ID}/`, data);
    },
    authPostWithAction: (key: any, URL: string, ID: number, action: any, data?: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).put(`${URL}/${ID}/${action}`, data);
    },
    authPostNoID: (key: any, URL: string, data: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).post(`${URL}/`, data);
    },
    authPut: (key: any, URL: string, ID: number, data: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).put(`${URL}/${ID}/`, data);
    },
    authDelete: (key: any, URL: string, ID: number, data?: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).delete(`${URL}/${ID}/`, data);
    },
    authGet: (key: any, URL: string, ID: number, data?: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).get(`${URL}/${ID}/`, data);
    },
    authGetWithAction: (key: any, URL: string, ID: number, action: any, data?: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).get(`${URL}/${ID}/${action}/`, data);
    },
    authGetNoID: (key: any, URL: string, data?: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).get(`${URL}/`, data);
    },
    authPatch: (key: any, URL: string, ID: number, data: any): Promise<AxiosResponse<any>> => {
        return authorizedClient(key).patch(`${URL}/${ID}/many/Categories`, data);
    },
}

export const handleApiError = (error: AxiosError) => {
    console.log(error)
    if (error.response?.data) {
        if (typeof error.response.data?.messages === "object") {
            error.response.data?.messages.forEach((message) => {
                // toast.error(message.message);
            });
        } else if (typeof error.response.data?.message === "string") {
            // toast.error(error.response.data.message);
        } else if (error.response.data.toString().length <= 64) {
            // toast.error(error.response.data);
        } else {
            // toast.error('Es ist ein unbekannter Fehler aufgetreten.');
        }

        // if ((typeof error.response.data?.action === "string") && error.response.data.action === 'LOGOUT') {
        //     window.location.replace('/logout');
        // }
    }
}

export interface ApiMessage {
    message: string
}

export const Client = {
    Raw: client,
    baseURL: baseURL,
    Basket: BasketClient,
    Member: MemberClient,
    Abo: AboClient,
    Vendor: VendorClient,
    Coupon: CouponClient,
    Bookmark: BookmarkClient,
    Auth: AuthClient,
    Product: ProdClient,
    AuthVendor: AuthVendorClient,
}
